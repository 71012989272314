html {
    padding-top: 56px;
    scroll-padding-top: 56px;
}

header {
    height: 100vh ;
    background: url(../img/container-row-front-side.jpg) center / cover;
}

section {
    padding-top: 30px;
}

#map {
    width: 100%;
    height: 70vh;
    object-fit: cover;
}